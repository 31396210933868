import React from 'react';
import { CardContent, CardMedia, Typography, Button, Box } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EventCard = ({ event }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/events/${event._id}`);
  };

  const cardStyles = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  };

  const imageStyles = {
    borderRadius: '8px',
    width: isMobile ? '100%' : '270px',
    height: isMobile ? 'auto' : '226px',
    objectFit: 'cover',
    marginRight: isMobile ? '0' : '16px',
    marginBottom: isMobile ? '16px' : '0',
  };

  const contentStyles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  };

  const headerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#555',
    marginBottom: '12px',
    flexWrap: 'wrap',
  };

  const footerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 'auto',
    borderTop: '1px solid #e0e0e0',
    paddingTop: '16px',
    flexDirection: isMobile ? 'column' : 'row',
    gap: isMobile ? '12px' : '0',
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <Box style={cardStyles} onClick={handleCardClick}>
      <CardMedia
        component="img"
        image={`${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(event.imageUrl)}`}
        alt={event.name}
        style={imageStyles}
      />
      <CardContent style={contentStyles}>
        <Typography gutterBottom variant="h5" component="div">
          {event.name}
        </Typography>
        <div style={headerStyles}>
          <EventIcon />
          <Typography variant="body2">{formatDate(event.date)}</Typography>
          <AccessTimeIcon />
          <Typography variant="body2">
            {formatTime(event.date)}
          </Typography>
          <LocationOnIcon />
          <Typography variant="body2">{event.address}, {event.city}, {event.country}, {event.zipcode}</Typography>
        </div>
        <Typography variant="body2" color="text.secondary" style={{ marginBottom: '16px' }}>
          {event.description}
        </Typography>
        <div style={footerStyles}>
          {console.log(event.isActive)}
        {event.isActive ? (
            <Button
              variant="outlined"
              href={`/api/ticket/buy-ticket?eventId=${event._id}`}
              onClick={(e) => e.stopPropagation()}
              sx={{
                color: '#e28743', // Цвет текста
                borderColor: '#e28743', // Цвет границы
                '&:hover': {
                  borderColor: '#e28743',
                  backgroundColor: 'rgba(226, 135, 67, 0.1)', // Легкий фон при наведении
                },
              }}
            >
              Buy Tickets
            </Button>
          ) : (
            <Box
              sx={{
                padding: '8px 16px',
                border: '1px solid #e28743',
                borderRadius: '4px',
                color: '#e28743',
                backgroundColor: 'rgba(226, 135, 67, 0.1)',
                textAlign: 'center',
              }}
            >
              Sold Out
            </Box>
          )}
        </div>
      </CardContent>
    </Box>
  );
};

export default EventCard;
