import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const NotFoundPage = () => {
  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#f8f8f8",
        padding: "20px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <ErrorOutlineIcon sx={{ fontSize: "4rem", color: "#e28743", mr: 1 }} />
        <Typography
          variant="h1"
          sx={{ fontSize: "3rem", fontWeight: "bold", color: "#333" }}
        >
          404
        </Typography>
      </Box>
      <Typography variant="h4" gutterBottom sx={{ color: "#555", mb: 3 }}>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: "#777", mb: 4 }}>
        The page you’re looking for doesn’t exist or was removed.
      </Typography>
      <Box>
        <Button
          variant="contained"
          component={Link}
          to="/"
          sx={{
            backgroundColor: "#e28743",
            color: "#fff",
            padding: "10px 20px",
            fontSize: "16px",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#d97533",
            },
          }}
        >
          Back to Home
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to="/events"
          sx={{
            borderColor: "#e28743",
            color: "#e28743",
            padding: "10px 20px",
            fontSize: "16px",
            "&:hover": {
              borderColor: "#d97533",
              color: "#d97533",
            },
          }}
        >
          Explore Events
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
