// src/hooks/useRegister.js
import { useState } from 'react';
import axios from 'axios';
import { validateRegistration } from '../utils/validation';

const useRegister = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const register = async ({ username, email, password, confirmPassword }) => {
    // Валидация данных перед отправкой
    const values = { username, email, password, confirmPassword };
    const errors = validateRegistration(values);

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HAMBAX_NEW_API_URL}/auth/registration/register`,
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Registration successful! You can now log in.');
        setErrorMessage('');
        setValidationErrors({});
      } else {
        setErrorMessage('Registration failed. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        setErrorMessage('No response from the server. Please try again.');
      } else {
        setErrorMessage('An error occurred during registration. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    errorMessage,
    successMessage,
    validationErrors,
    register,
  };
};

export default useRegister;
