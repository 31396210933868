// src/screens/LoginScreen.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Link, CircularProgress } from '@mui/material';
import useLogin from '../hooks/useLogin';
import { validateLogin } from '../utils/validation';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { isLoading, apiError, login } = useLogin();

  const handleLogin = () => {
    const values = { email, password };
    const validationErrors = validateLogin(values);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      login({ email, password });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>

        {apiError && (
          <Typography color="error" variant="body2">
            {apiError}
          </Typography>
        )}

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
        />

        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{ mt: 2 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Login'}
        </Button>

        <Box mt={2} display="flex" justifyContent="space-between" width="100%">
          <Link href="/register" variant="body2">
            Don't have an account? Sign Up
          </Link>
          <Link href="/forgot-password" variant="body2">
            Forgot Password?
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginScreen;
