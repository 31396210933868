/**
 * Функция для валидации данных регистрации.
 * @param {Object} values - Объект с полями формы.
 * @returns {Object} Объект с ошибками (пустой, если ошибок нет).
 */
export const validateRegistration = (values) => {
    const errors = {};
  
    if (!values.username.trim()) {
      errors.username = 'Username is required';
    }
  
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
  
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }
  
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
  
    return errors;
  };


  /**
 * Функция для валидации данных логина.
 * @param {Object} values - Объект с полями формы.
 * @returns {Object} Объект с ошибками (пустой, если ошибок нет).
 */
export const validateLogin = (values) => {
    const errors = {};
  
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
  
    if (!values.password) {
      errors.password = 'Password is required';
    }
  
    return errors;
  };

  /**
 * Функция для валидации пароля.
 * @param {string} password - Пароль для проверки.
 * @returns {string | null} Сообщение об ошибке (или null, если ошибок нет).
 */
export const validatePassword = (password) => {
  if (!password) {
      return 'Password is required';
  } else if (password.length < 6) {
      return 'Password must be at least 6 characters long';
  }
  return null;
};

/**
 * Функция для валидации данных контактной формы.
 * @param {Object} values - Объект с полями формы.
 * @returns {Object} Объект с ошибками (пустой, если ошибок нет).
 */
export const validateContactForm = (values) => {
  const errors = {};

  if (!values.name.trim()) {
    errors.name = 'Name is required';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.message.trim()) {
    errors.message = 'Message is required';
  } else if (values.message.length < 10) {
    errors.message = 'Message must be at least 10 characters long';
  }

  return errors;
};
