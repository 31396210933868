import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress, IconButton, InputAdornment, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../utils/validation';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ForgotPasswordScreen = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(['', '', '', '']);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [apiMessage, setApiMessage] = useState('');

  const handleSendCode = async () => {
    setIsLoading(true);
    setApiMessage('');
    // Запрос для отправки кода на email
    try {
      const response = await fetch('https://example.com/api/send-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) throw new Error(t('api.errorSendingCode'));
      setIsCodeSent(true);
    } catch (error) {
      setApiMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    setIsLoading(true);
    setApiMessage('');
    // Запрос для проверки кода
    try {
      const response = await fetch('https://example.com/api/verify-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: code.join('') }),
      });
      if (!response.ok) throw new Error(t('api.invalidCode'));
      setIsCodeValid(true);
    } catch (error) {
      setApiMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    const validationErrors = {};
    if (!validatePassword(newPassword, t)) validationErrors.newPassword = t('validation.passwordRequired');
    if (newPassword !== confirmPassword) validationErrors.confirmPassword = t('validation.confirmPasswordMismatch');
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    setApiMessage('');
    // Запрос для смены пароля
    try {
      const response = await fetch('https://example.com/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password: newPassword }),
      });
      if (!response.ok) throw new Error(t('api.errorResettingPassword'));
      setApiMessage(t('api.passwordResetSuccess'));
    } catch (error) {
      setApiMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCodeInputChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (newCode.join('').length === 4) handleVerifyCode();
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
        <Typography variant="h4" gutterBottom>
          {t('forgotPassword.title')}
        </Typography>
        {apiMessage && <Alert severity="info">{apiMessage}</Alert>}

        {!isCodeSent && (
          <>
            <TextField
              label={t('forgotPassword.emailLabel')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSendCode}
              disabled={isLoading}
              sx={{ mt: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : t('forgotPassword.sendCodeButton')}
            </Button>
          </>
        )}

        {isCodeSent && !isCodeValid && (
          <Box display="flex" justifyContent="center" gap={1} mt={2}>
            {[0, 1, 2, 3].map((i) => (
              <TextField
                key={i}
                inputProps={{ maxLength: 1 }}
                onChange={(e) => handleCodeInputChange(i, e.target.value)}
                variant="outlined"
                margin="dense"
                sx={{ width: 50 }}
              />
            ))}
          </Box>
        )}

        {isCodeValid && (
          <>
            <TextField
              label={t('forgotPassword.newPasswordLabel')}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t('forgotPassword.confirmPasswordLabel')}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleChangePassword}
              disabled={isLoading}
              sx={{ mt: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : t('forgotPassword.resetPasswordButton')}
            </Button>
          </>
        )}

        {apiMessage === t('api.passwordResetSuccess') && (
          <Button variant="outlined" color="primary" fullWidth sx={{ mt: 2 }} href="/login">
            {t('forgotPassword.goToLogin')}
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPasswordScreen;
