import React from "react";
import { BrowserRouter  as Router, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CookieConsent from "./components/CookieConsent";
import theme from "./theme";

import Home from "./screens/Home";
import Events from "./screens/Events";
import OrganizationPage from "./screens/OrganizationPage";
import OrganizationsList from "./screens/OrganizationsList";
import EventDetails from "./screens/EventDetails";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Login from "./screens/LoginScreen";
import TemporaryNotice from "./screens/TemporaryNotice";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import RegisterPage from "./screens/RegisterPage";
import Contact from "./screens/Contact";
import NotFoundPage from "./screens/NotFoundPage";

function App() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Router>
          <Navbar />
          <div style={{ flex: "1" }}>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/organization/vpiska" />}
              />
              <Route
                path="/vpiska"
                element={<Navigate to="/organization/vpiska" />}
              />
              <Route
                path="/home"
                element={
                  <>
                    <Helmet>
                      <title>{t("home")}</title>
                    </Helmet>
                    <Home />
                  </>
                }
              />
              <Route
                path="/events"
                element={
                  <>
                    <Helmet>
                      <title>{t("events")}</title>
                    </Helmet>
                    <Events />
                  </>
                }
              />
              <Route
                path="/events/:eventId"
                element={
                  <>
                    <Helmet>
                      <title>{t("eventDetails")}</title>
                    </Helmet>
                    <EventDetails />
                  </>
                }
              />
              <Route
                path="/organization/:slug"
                element={
                  <>
                    <Helmet>
                      <title>{t("organizationDetails")}</title>
                    </Helmet>
                    <OrganizationPage />
                  </>
                }
              />
              <Route
                path="/organizations"
                element={
                  <>
                    <Helmet>
                      <title>{t("organizations")}</title>
                    </Helmet>
                    <OrganizationsList />
                  </>
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  <>
                    <Helmet>
                      <title>{t("privacyPolicy")}</title>
                    </Helmet>
                    <PrivacyPolicy />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Helmet>
                      <title>Contact</title>
                    </Helmet>
                    <Contact />
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    <Helmet>
                      <title>Login</title>
                    </Helmet>
                    <Login />
                  </>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <>
                    <Helmet>
                      <title>Forgot Password</title>
                    </Helmet>
                    <ForgotPasswordScreen />
                  </>
                }
              />
              <Route
                path="/register"
                element={
                  <>
                    <Helmet>
                      <title>Register</title>
                    </Helmet>
                    <RegisterPage />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Helmet>
                      <title>Not found</title>
                    </Helmet>
                    <NotFoundPage />
                  </>
                }
              />
            </Routes>
          </div>
          <Footer />
          <CookieConsent />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
