import { useState } from 'react';
import axios from 'axios';

const useContactFormSubmit = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const submitContactForm = async (formData) => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    try {
      await axios.post(`${process.env.REACT_APP_HAMBAX_NEW_API_URL}/api/notifications/email/contact-form-send`, formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      setSuccess(true);
    } catch (err) {
      setError("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, error, success, submitContactForm };
};

export default useContactFormSubmit;
