import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useContactFormSubmit from "../hooks/useContactFormSubmit";
import { validateContactForm } from "../utils/validation";
import SuccessImage from "../assets/undraw_mindfulness_8gqa.svg"; // Импорт изображения

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const { isSubmitting, error, success, submitContactForm } =
    useContactFormSubmit();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateContactForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    await submitContactForm(formData);
  };

  if (success) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ my: 4, textAlign: "center" }}>
          <Typography variant="h5" color="primary" gutterBottom>
            {t("contact.successMessage")}
          </Typography>
          <img
            src={SuccessImage}
            alt="Success"
            style={{ marginTop: "20px", maxWidth: "100%", height: "auto" }}
          />{" "}
          {/* Добавлено изображение */}
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t("contact.title")}
        </Typography>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {t("contact.errorMessage")}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={t("contact.name")}
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
            required
          />
          <TextField
            fullWidth
            label={t("contact.email")}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
            required
          />
          <TextField
            fullWidth
            label={t("contact.message")}
            name="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            margin="normal"
            error={!!errors.message}
            helperText={errors.message}
            required
          />
          <Box sx={{ mt: 2, position: "relative" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                t("contact.submit")
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default Contact;
